import React, { useState } from 'react';
import './NewCaptcha.scss';
import './mobile/index.scss';
//@ts-ignore
import SliderCaptcha from '@slider-captcha/react';
import { withTranslation } from '@i18n';

const NewCaptcha = (props: any) => {
  const { t, successFunc, resolvedValidText } = props;
  const tokenCallback = (token: any) => {
    if (token && successFunc) {
      successFunc();
    }
  };
  return (
    <div className="CaptchaComponent">
      <div className="CaptchaComponent-wrapper">
        <SliderCaptcha
          create={`${process.env.SITE_URL}/captcha/create`}
          verify={`${process.env.SITE_URL}/captcha/verify`}
          callback={tokenCallback}
          text={{ anchor: `${t('Я не робот')}`, challenge: t('Потяните для решения пазла') }}
        />
      </div>
      <div className="captcha-error">{resolvedValidText}</div>
    </div>
  );
};
NewCaptcha.getInitialProps = async ({
  req,
}: any) => ({
  namespacesRequired: ['common'],
});
export default withTranslation('common')(NewCaptcha);
